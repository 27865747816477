<template>
  <div id="user-list">

    <!-- list filters -->
    <v-card>
      <v-card-title>
        MOH Requests
      </v-card-title>
      <v-row class="px-2 ma-0">
        <!-- role filter -->
        <v-col
          cols="12"
          sm="4"
        >
          <v-select
            v-model="roleFilter"
            placeholder="Select Vaccine"
            :items="roleOptions"
            item-text="title"
            item-value="value"
            outlined
            clearable
            dense
            hide-details
          ></v-select>
        </v-col>

        <!-- plan filter -->
        <v-col
          cols="12"
          sm="4"
        >
          <v-select
            v-model="planFilter"
            placeholder="Select facility"
            :items="planOptions"
            item-text="title"
            item-value="value"
            outlined
            dense
            clearable
            hide-details
          ></v-select>
        </v-col>

        <!-- status filter -->
        <v-col
          cols="12"
          sm="4"
        >
          <v-select
            v-model="statusFilter"
            placeholder="Select Status"
            :items="statusOptions"
            item-text="title"
            item-value="value"
            outlined
            dense
            clearable
            hide-details
          ></v-select>
        </v-col>
      </v-row>

      <v-divider class="mt-4"></v-divider>

      <!-- actions -->
      <v-card-text class="d-flex align-center flex-wrap pb-0">
        <!-- search -->
        <v-text-field
          v-model="searchQuery"
          placeholder="Search"
          outlined
          hide-details
          dense
          class="user-search me-3 mb-4"
        >
        </v-text-field>

        <v-spacer></v-spacer>

        <div class="d-flex align-center flex-wrap">
          <v-btn
          v-if="false"
            color="primary"
            class="mb-4 me-3"
            @click.stop="isAddNewUserSidebarActive = !isAddNewUserSidebarActive"
          >
            <v-icon>{{ icons.mdiPlus }}</v-icon>
            <span>Add Request</span>
          </v-btn>
        </div>
      </v-card-text>

      <!-- table -->
      <v-data-table
        v-model="selectedRows"
        :headers="tableColumns"
        :items="mohListTable"
        :options.sync="options"
        :server-items-length="totalMohListTable"
        :loading="loading"
        show-select
      >
        <!-- name -->
        <template #[`item.name`]="{item}">
          <div class="d-flex align-center">
            {{ item.name }}
            <div class="d-flex flex-column ms-3">
              <router-link
                :to="{ name : 'apps-user-view', params : { id : item.id } }"
                class="text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none"
              >
                {{ item.fullName }}
              </router-link>
              <span class="text-capitalize font-weight-semibold text--primary">{{ item.vaccine.name }}</span>
            </div>
          </div>
        </template>

   

        <!-- quantity -->
        <template #[`item.quantity`]="{item}">
          <span class="text-capitalize font-weight-semibold text--primary">{{ item.quantity }}</span>
        </template>

        <!-- facilities -->
        <template #[`item.facilities`]="{item}">
          <span class="text-capitalize font-weight-semibold text--primary">{{ getFacilities(item.groups)  }}</span>
        </template>
        <!-- corporates -->
        <template #[`item.corporates`]="{item}">
          <span class="text-capitalize font-weight-semibold text--primary">{{ getCorporates(item.groups) }}</span>
        </template>

          <!-- amount -->
        <template #[`item.amount`]="{item}">
          <span class="text-capitalize font-weight-semibold text--primary">{{ item.amount }}</span>
        </template>
        <!-- status -->
        <template #[`item.status`]="{item}">
          <v-chip
            small
            class="v-chip-light-bg font-weight-semibold text-capitalize"
          >
            <span v-if="item.status">
            {{ item.status.name }}
            </span>
          </v-chip>
        </template>

        <!-- actions -->
        <template #[`item.actions`]="{item}">
          <v-menu
            bottom
            left
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item
                :to="{name:'moh-requests-view', params:{id:item.id}}"
              >
                <v-list-item-title>
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiFileDocumentOutline }}
                  </v-icon>
                  <span>Details</span>
                </v-list-item-title>
              </v-list-item>

              <v-list-item link>
                <v-list-item-title>
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiFilePdfOutline }}
                  </v-icon>
                  <span>Download</span>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
  // eslint-disable-next-line object-curly-newline
  import {
    mdiSquareEditOutline,
    mdiDotsVertical,
    mdiPlus,
    mdiFileDocumentOutline,
    mdiDeleteOutline,
    mdiExportVariant,
    mdiAccountOutline,
    mdiFilePdfOutline,
  } from '@mdi/js'
  import store from '@/store'
  import { onUnmounted, ref } from '@vue/composition-api'

  // sidebar
  import { avatarText } from '@core/utils/filter'
  import mhoStoreModule from './mohStoreModule'

  import fetchMohRequests from './useMohList'

  export default {
    setup() {
      const MOH_APP_STORE_MODULE_NAME = 'mohRequest'

      // Register module
      if (!store.hasModule(MOH_APP_STORE_MODULE_NAME)) store.registerModule(MOH_APP_STORE_MODULE_NAME, mhoStoreModule)

      // UnRegister on leave
      onUnmounted(() => {
        if (store.hasModule(MOH_APP_STORE_MODULE_NAME)) store.unregisterModule(MOH_APP_STORE_MODULE_NAME)
      })

      const {
        mohListTable,
        tableColumns,
        searchQuery,
        roleFilter,
        planFilter,
        statusFilter,
        totalMohListTable,
        loading,
        options,
        mohTotalLocal,
        selectedRows,
        fetchRequests,
        resolveUserRoleVariant,
        resolveUserRoleIcon,
        resolveUserStatusVariant,
        resolveUserTotalIcon,
      } = fetchMohRequests()

      const isAddNewUserSidebarActive = ref(false)

      const roleOptions = [{ title: 'Johnson & Johnson', value: 'Johnson_Johnson' }]

      const planOptions = [
        { title: 'kepsa-test-1', value: 'company' },
        { title: 'kepsa-test-2', value: 'company' },
      ]

      const statusOptions = [
        { title: 'Pending', value: 'pending' },
        { title: 'MOH Approved', value: 'approved' },
        { title: 'Rejected', value: 'Rejected' },
        { title: 'Vaccine Delivered', value: 'delivered' },
      ]

      return {
        mohListTable,
        tableColumns,
        searchQuery,
        roleFilter,
        planFilter,
        statusFilter,
        totalMohListTable,
        roleOptions,
        planOptions,
        statusOptions,
        loading,
        options,
        mohTotalLocal,
        isAddNewUserSidebarActive,
        selectedRows,
        avatarText,
        resolveUserRoleVariant,
        resolveUserRoleIcon,
        resolveUserStatusVariant,
        resolveUserTotalIcon,
        fetchRequests,
        // icons
        icons: {
          mdiSquareEditOutline,
          mdiFileDocumentOutline,
          mdiDotsVertical,
          mdiDeleteOutline,
          mdiPlus,
          mdiExportVariant,
          mdiAccountOutline,
          mdiFilePdfOutline,
        },
      }
    },
    methods: {
      getCorporates(groups) {
        let strCorporate = ''
        groups.map(item => {
          item.group.corporates.map(corporate => {
            strCorporate += corporate.vaccine_request.corporate.name + ', '
          })
        })
        return strCorporate
      },
      getFacilities(groups) {
        let strFacility = ''
        groups.map(item => {
          strFacility += item.group.facility.name
        })
        return strFacility
      },
    },
  }
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
</style>
