import store from '@/store'
import {
  mdiAccountCheckOutline,
  mdiAccountOutline,
  mdiAccountPlusOutline,
  mdiAccountRemoveOutline,
  mdiCogOutline,
  mdiDatabaseOutline,
  mdiDnsOutline,
  mdiPencilOutline,
} from '@mdi/js'
import { ref, watch } from '@vue/composition-api'

export default function useMohList() {
  const userListTable = ref([])

  const tableColumns = [
    { text: 'Vaccine Name', value: 'name' },
    { text: 'Quantity', value: 'quantity' },
    { text: 'Facilities', value: 'facilities' },
    { text: 'Corporates', value: 'corporates' },
    { text: 'Amount', value: 'amount' },
    { text: 'STATUS', value: 'status' },
    {
      text: 'ACTIONS',
      value: 'actions',
      align: 'center',
      sortable: false,
    },
  ]

  const searchQuery = ref('')
  const roleFilter = ref(null)
  const planFilter = ref(null)
  const statusFilter = ref(null)
  const mohListTable = ref([])
  const totalMohListTable = ref(0)
  const loading = ref(false)
  const options = ref({
    sortBy: ['id'],
    sortDesc: [true],
  })
  const mohTotalLocal = ref([])
  const selectedRows = ref([])

  const groups = ref([])
  const totalGroups = ref([])

  // fetch data
  const fetchMohRequests = () => {
    store
      .dispatch('mohRequest/fetchMohRequests')
      .then(response => {
        mohListTable.value = response.data['result']
        totalMohListTable.value = response.data['result'].length
        mohTotalLocal.value = response.data['result'].length
        loading.value = false
      })
      .catch(error => {
        console.log(error)
      })
  }

  const fetchMohRequest = payload => {
    loading.value = true
    store
      .dispatch('mohRequest/fetchMohRequest', payload)
      .then(response => {
        store.dispatch('mohRequest/setSelected', { item: response.data['result'] })
        groups.value = response.data['result']
        totalGroups.value = response.data['result'].size
      })
      .catch(error => {
        console.log(error)
      })
      .finally(() => {
        loading.value = false
      })
  }

  watch([searchQuery, roleFilter, planFilter, statusFilter, options], () => {
    loading.value = true
    selectedRows.value = []
    fetchMohRequests()
  })

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveUserRoleVariant = role => {
    if (role === 'USER') return 'primary'
    if (role === 'author') return 'warning'
    if (role === 'maintainer') return 'success'
    if (role === 'EDITOR') return 'info'
    if (role === 'ADMIN') return 'error'

    return 'primary'
  }

  const resolveUserRoleIcon = role => {
    if (role === 'USER') return mdiAccountOutline
    if (role === 'author') return mdiCogOutline
    if (role === 'maintainer') return mdiDatabaseOutline
    if (role === 'EDITOR') return mdiPencilOutline
    if (role === 'ADMIN') return mdiDnsOutline

    return mdiAccountOutline
  }

  const resolveUserStatusVariant = status => {
    if (status === 'PENDING') return 'warning'
    if (status === 'ACTIVE') return 'success'
    if (status === 'INACTIVE') return 'secondary'

    return 'primary'
  }

  const resolveUserTotalIcon = total => {
    if (total === 'Total Users') return { icon: mdiAccountOutline, color: 'primary' }
    if (total === 'Paid Users') return { icon: mdiAccountPlusOutline, color: 'error' }
    if (total === 'Active Users') return { icon: mdiAccountCheckOutline, color: 'success' }
    if (total === 'Pending Users') return { icon: mdiAccountRemoveOutline, color: 'warning' }

    return { icon: mdiAccountOutline, color: 'primary' }
  }

  return {
    mohListTable,
    tableColumns,
    searchQuery,
    roleFilter,
    planFilter,
    statusFilter,
    totalMohListTable,
    groups,
    totalGroups,
    loading,
    options,
    mohTotalLocal,
    selectedRows,
    fetchMohRequests,
    fetchMohRequest,
    resolveUserRoleVariant,
    resolveUserRoleIcon,
    resolveUserStatusVariant,
    resolveUserTotalIcon,
  }
}
